package org.ailingo.app

import kotlin.Long
import kotlin.String

public data class Token(
  public val id: Long,
  public val token: String,
  public val refresh_token: String,
)
