package org.ailingo.app

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.SqlDriver
import kotlin.Any
import kotlin.Long
import kotlin.String

public class TokenQueries(
  driver: SqlDriver,
) : SuspendingTransacterImpl(driver) {
  public fun <T : Any> getTokens(mapper: (
    id: Long,
    token: String,
    refresh_token: String,
  ) -> T): Query<T> = Query(985_517_353, arrayOf("Token"), driver, "Token.sq", "getTokens",
      "SELECT Token.id, Token.token, Token.refresh_token FROM Token") { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!
    )
  }

  public fun getTokens(): Query<Token> = getTokens { id, token, refresh_token ->
    Token(
      id,
      token,
      refresh_token
    )
  }

  public suspend fun insertTokens(token: String, refreshToken: String) {
    driver.execute(-203_788_678, """INSERT INTO Token (token, refresh_token) VALUES (?, ?)""", 2) {
          bindString(0, token)
          bindString(1, refreshToken)
        }.await()
    notifyQueries(-203_788_678) { emit ->
      emit("Token")
    }
  }

  public suspend fun updateTokens(
    newToken: String,
    newRefreshToken: String,
    oldRefreshToken: String,
  ) {
    driver.execute(-1_898_112_886, """
        |UPDATE Token
        |SET token = ?, refresh_token = ?
        |WHERE refresh_token = ?
        """.trimMargin(), 3) {
          bindString(0, newToken)
          bindString(1, newRefreshToken)
          bindString(2, oldRefreshToken)
        }.await()
    notifyQueries(-1_898_112_886) { emit ->
      emit("Token")
    }
  }

  public suspend fun deleteToken(token: String) {
    driver.execute(83_130_727, """DELETE FROM Token WHERE token = ?""", 1) {
          bindString(0, token)
        }.await()
    notifyQueries(83_130_727) { emit ->
      emit("Token")
    }
  }

  public suspend fun deleteRefreshToken(refreshToken: String) {
    driver.execute(786_460_848, """DELETE FROM Token WHERE refresh_token = ?""", 1) {
          bindString(0, refreshToken)
        }.await()
    notifyQueries(786_460_848) { emit ->
      emit("Token")
    }
  }

  public suspend fun deleteTokens() {
    driver.execute(-1_717_914_644, """DELETE FROM Token""", 0).await()
    notifyQueries(-1_717_914_644) { emit ->
      emit("Token")
    }
  }
}
