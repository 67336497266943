@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package ailingo.composeapp.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.InternalResourceApi
import org.jetbrains.compose.resources.StringResource

private object CommonMainString0 {
  public val already_have_an_account: StringResource by 
      lazy { init_already_have_an_account() }

  public val app_name: StringResource by 
      lazy { init_app_name() }

  public val app_name_underspace: StringResource by 
      lazy { init_app_name_underspace() }

  public val avatar: StringResource by 
      lazy { init_avatar() }

  public val back: StringResource by 
      lazy { init_back() }

  public val back_input_fields: StringResource by 
      lazy { init_back_input_fields() }

  public val back_to_the_selection: StringResource by 
      lazy { init_back_to_the_selection() }

  public val business: StringResource by 
      lazy { init_business() }

  public val cartoons: StringResource by 
      lazy { init_cartoons() }

  public val choose_image: StringResource by 
      lazy { init_choose_image() }

  public val continue_app: StringResource by 
      lazy { init_continue_app() }

  public val continue_with_default_image: StringResource by 
      lazy { init_continue_with_default_image() }

  public val create_your_account: StringResource by 
      lazy { init_create_your_account() }

  public val culture_and_art: StringResource by 
      lazy { init_culture_and_art() }

  public val definitions: StringResource by 
      lazy { init_definitions() }

  public val dictionary: StringResource by 
      lazy { init_dictionary() }

  public val dont_have_an_account: StringResource by 
      lazy { init_dont_have_an_account() }

  public val email: StringResource by 
      lazy { init_email() }

  public val exit: StringResource by 
      lazy { init_exit() }

  public val fashion_and_style: StringResource by 
      lazy { init_fashion_and_style() }

  public val food_and_drinks: StringResource by 
      lazy { init_food_and_drinks() }

  public val forgot_password: StringResource by 
      lazy { init_forgot_password() }

  public val free_mode: StringResource by 
      lazy { init_free_mode() }

  public val get_started: StringResource by 
      lazy { init_get_started() }

  public val health_and_medicine: StringResource by 
      lazy { init_health_and_medicine() }

  public val history: StringResource by 
      lazy { init_history() }

  public val history_of_search: StringResource by 
      lazy { init_history_of_search() }

  public val lets_add_your_avatar: StringResource by 
      lazy { init_lets_add_your_avatar() }

  public val lets_other_get_to_know_you: StringResource by 
      lazy { init_lets_other_get_to_know_you() }

  public val literature: StringResource by 
      lazy { init_literature() }

  public val log_in: StringResource by 
      lazy { init_log_in() }

  public val login: StringResource by 
      lazy { init_login() }

  public val login_cannot_be_empty: StringResource by 
      lazy { init_login_cannot_be_empty() }

  public val made_for: StringResource by 
      lazy { init_made_for() }

  public val message: StringResource by 
      lazy { init_message() }

  public val movies: StringResource by 
      lazy { init_movies() }

  public val my_string: StringResource by 
      lazy { init_my_string() }

  public val name: StringResource by 
      lazy { init_name() }

  public val nature_and_ecology: StringResource by 
      lazy { init_nature_and_ecology() }

  public val new_Test: StringResource by 
      lazy { init_new_Test() }

  public val news: StringResource by 
      lazy { init_news() }

  public val next: StringResource by 
      lazy { init_next() }

  public val no_definitions: StringResource by 
      lazy { init_no_definitions() }

  public val password: StringResource by 
      lazy { init_password() }

  public val password_cannot_be_empty: StringResource by 
      lazy { init_password_cannot_be_empty() }

  public val press_kit: StringResource by 
      lazy { init_press_kit() }

  public val private_police: StringResource by 
      lazy { init_private_police() }

  public val profile: StringResource by 
      lazy { init_profile() }

  public val reset_your_password: StringResource by 
      lazy { init_reset_your_password() }

  public val science_and_education: StringResource by 
      lazy { init_science_and_education() }

  public val search: StringResource by 
      lazy { init_search() }

  public val sign_up: StringResource by 
      lazy { init_sign_up() }

  public val sport: StringResource by 
      lazy { init_sport() }

  public val technologies: StringResource by 
      lazy { init_technologies() }

  public val topics: StringResource by 
      lazy { init_topics() }

  public val trips: StringResource by 
      lazy { init_trips() }

  public val usage_examples: StringResource by 
      lazy { init_usage_examples() }

  public val welcome_back: StringResource by 
      lazy { init_welcome_back() }

  public val whats_new: StringResource by 
      lazy { init_whats_new() }
}

@InternalResourceApi
internal fun _collectCommonMainString0Resources(map: MutableMap<String, StringResource>) {
  map.put("already_have_an_account", CommonMainString0.already_have_an_account)
  map.put("app_name", CommonMainString0.app_name)
  map.put("app_name_underspace", CommonMainString0.app_name_underspace)
  map.put("avatar", CommonMainString0.avatar)
  map.put("back", CommonMainString0.back)
  map.put("back_input_fields", CommonMainString0.back_input_fields)
  map.put("back_to_the_selection", CommonMainString0.back_to_the_selection)
  map.put("business", CommonMainString0.business)
  map.put("cartoons", CommonMainString0.cartoons)
  map.put("choose_image", CommonMainString0.choose_image)
  map.put("continue_app", CommonMainString0.continue_app)
  map.put("continue_with_default_image", CommonMainString0.continue_with_default_image)
  map.put("create_your_account", CommonMainString0.create_your_account)
  map.put("culture_and_art", CommonMainString0.culture_and_art)
  map.put("definitions", CommonMainString0.definitions)
  map.put("dictionary", CommonMainString0.dictionary)
  map.put("dont_have_an_account", CommonMainString0.dont_have_an_account)
  map.put("email", CommonMainString0.email)
  map.put("exit", CommonMainString0.exit)
  map.put("fashion_and_style", CommonMainString0.fashion_and_style)
  map.put("food_and_drinks", CommonMainString0.food_and_drinks)
  map.put("forgot_password", CommonMainString0.forgot_password)
  map.put("free_mode", CommonMainString0.free_mode)
  map.put("get_started", CommonMainString0.get_started)
  map.put("health_and_medicine", CommonMainString0.health_and_medicine)
  map.put("history", CommonMainString0.history)
  map.put("history_of_search", CommonMainString0.history_of_search)
  map.put("lets_add_your_avatar", CommonMainString0.lets_add_your_avatar)
  map.put("lets_other_get_to_know_you", CommonMainString0.lets_other_get_to_know_you)
  map.put("literature", CommonMainString0.literature)
  map.put("log_in", CommonMainString0.log_in)
  map.put("login", CommonMainString0.login)
  map.put("login_cannot_be_empty", CommonMainString0.login_cannot_be_empty)
  map.put("made_for", CommonMainString0.made_for)
  map.put("message", CommonMainString0.message)
  map.put("movies", CommonMainString0.movies)
  map.put("my_string", CommonMainString0.my_string)
  map.put("name", CommonMainString0.name)
  map.put("nature_and_ecology", CommonMainString0.nature_and_ecology)
  map.put("new_Test", CommonMainString0.new_Test)
  map.put("news", CommonMainString0.news)
  map.put("next", CommonMainString0.next)
  map.put("no_definitions", CommonMainString0.no_definitions)
  map.put("password", CommonMainString0.password)
  map.put("password_cannot_be_empty", CommonMainString0.password_cannot_be_empty)
  map.put("press_kit", CommonMainString0.press_kit)
  map.put("private_police", CommonMainString0.private_police)
  map.put("profile", CommonMainString0.profile)
  map.put("reset_your_password", CommonMainString0.reset_your_password)
  map.put("science_and_education", CommonMainString0.science_and_education)
  map.put("search", CommonMainString0.search)
  map.put("sign_up", CommonMainString0.sign_up)
  map.put("sport", CommonMainString0.sport)
  map.put("technologies", CommonMainString0.technologies)
  map.put("topics", CommonMainString0.topics)
  map.put("trips", CommonMainString0.trips)
  map.put("usage_examples", CommonMainString0.usage_examples)
  map.put("welcome_back", CommonMainString0.welcome_back)
  map.put("whats_new", CommonMainString0.whats_new)
}

internal val Res.string.already_have_an_account: StringResource
  get() = CommonMainString0.already_have_an_account

private fun init_already_have_an_account(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:already_have_an_account", "already_have_an_account",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    10, 75),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 10,
    63),
    )
)

internal val Res.string.app_name: StringResource
  get() = CommonMainString0.app_name

private fun init_app_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:app_name", "app_name",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    126, 28),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 114,
    28),
    )
)

internal val Res.string.app_name_underspace: StringResource
  get() = CommonMainString0.app_name_underspace

private fun init_app_name_underspace(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:app_name_underspace", "app_name_underspace",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    86, 39),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 74,
    39),
    )
)

internal val Res.string.avatar: StringResource
  get() = CommonMainString0.avatar

private fun init_avatar(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:avatar", "avatar",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    155, 30),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 143,
    22),
    )
)

internal val Res.string.back: StringResource
  get() = CommonMainString0.back

private fun init_back(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:back", "back",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    350, 28),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 282,
    20),
    )
)

internal val Res.string.back_input_fields: StringResource
  get() = CommonMainString0.back_input_fields

private fun init_back_input_fields(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:back_input_fields", "back_input_fields",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    186, 85),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 166,
    57),
    )
)

internal val Res.string.back_to_the_selection: StringResource
  get() = CommonMainString0.back_to_the_selection

private fun init_back_to_the_selection(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:back_to_the_selection", "back_to_the_selection",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    272, 77),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 224,
    57),
    )
)

internal val Res.string.business: StringResource
  get() = CommonMainString0.business

private fun init_business(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:business", "business",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    379, 32),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 303,
    28),
    )
)

internal val Res.string.cartoons: StringResource
  get() = CommonMainString0.cartoons

private fun init_cartoons(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:cartoons", "cartoons",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    412, 48),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 332,
    28),
    )
)

internal val Res.string.choose_image: StringResource
  get() = CommonMainString0.choose_image

private fun init_choose_image(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:choose_image", "choose_image",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    461, 72),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 361,
    36),
    )
)

internal val Res.string.continue_app: StringResource
  get() = CommonMainString0.continue_app

private fun init_continue_app(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:continue_app", "continue_app",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    534, 48),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 398,
    32),
    )
)

internal val Res.string.continue_with_default_image: StringResource
  get() = CommonMainString0.continue_with_default_image

private fun init_continue_with_default_image(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:continue_with_default_image", "continue_with_default_image",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    583, 143),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 431,
    75),
    )
)

internal val Res.string.create_your_account: StringResource
  get() = CommonMainString0.create_your_account

private fun init_create_your_account(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:create_your_account", "create_your_account",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    727, 83),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 507,
    55),
    )
)

internal val Res.string.culture_and_art: StringResource
  get() = CommonMainString0.culture_and_art

private fun init_culture_and_art(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:culture_and_art", "culture_and_art",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    811, 75),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 563,
    43),
    )
)

internal val Res.string.definitions: StringResource
  get() = CommonMainString0.definitions

private fun init_definitions(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:definitions", "definitions",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    887, 51),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 607,
    35),
    )
)

internal val Res.string.dictionary: StringResource
  get() = CommonMainString0.dictionary

private fun init_dictionary(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:dictionary", "dictionary",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    939, 38),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 643,
    34),
    )
)

internal val Res.string.dont_have_an_account: StringResource
  get() = CommonMainString0.dont_have_an_account

private fun init_dont_have_an_account(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:dont_have_an_account", "dont_have_an_account",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    978, 60),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 678,
    56),
    )
)

internal val Res.string.email: StringResource
  get() = CommonMainString0.email

private fun init_email(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:email", "email",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1039, 57),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 735,
    21),
    )
)

internal val Res.string.exit: StringResource
  get() = CommonMainString0.exit

private fun init_exit(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:exit", "exit",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1097, 28),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 757,
    20),
    )
)

internal val Res.string.fashion_and_style: StringResource
  get() = CommonMainString0.fashion_and_style

private fun init_fashion_and_style(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fashion_and_style", "fashion_and_style",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1126, 57),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 778,
    49),
    )
)

internal val Res.string.food_and_drinks: StringResource
  get() = CommonMainString0.food_and_drinks

private fun init_food_and_drinks(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:food_and_drinks", "food_and_drinks",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1184, 55),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 828,
    43),
    )
)

internal val Res.string.forgot_password: StringResource
  get() = CommonMainString0.forgot_password

private fun init_forgot_password(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:forgot_password", "forgot_password",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1240, 59),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 872,
    47),
    )
)

internal val Res.string.free_mode: StringResource
  get() = CommonMainString0.free_mode

private fun init_free_mode(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:free_mode", "free_mode",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1300, 25),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 920,
    29),
    )
)

internal val Res.string.get_started: StringResource
  get() = CommonMainString0.get_started

private fun init_get_started(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:get_started", "get_started",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1326, 35),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 950,
    35),
    )
)

internal val Res.string.health_and_medicine: StringResource
  get() = CommonMainString0.health_and_medicine

private fun init_health_and_medicine(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:health_and_medicine", "health_and_medicine",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1362, 75),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 986,
    55),
    )
)

internal val Res.string.history: StringResource
  get() = CommonMainString0.history

private fun init_history(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:history", "history",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1548, 35),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 1128,
    27),
    )
)

internal val Res.string.history_of_search: StringResource
  get() = CommonMainString0.history_of_search

private fun init_history_of_search(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:history_of_search", "history_of_search",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1438, 109),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 1042,
    85),
    )
)

internal val Res.string.lets_add_your_avatar: StringResource
  get() = CommonMainString0.lets_add_your_avatar

private fun init_lets_add_your_avatar(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:lets_add_your_avatar", "lets_add_your_avatar",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1584, 140),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 1156,
    68),
    )
)

internal val Res.string.lets_other_get_to_know_you: StringResource
  get() = CommonMainString0.lets_other_get_to_know_you

private fun init_lets_other_get_to_know_you(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:lets_other_get_to_know_you", "lets_other_get_to_know_you",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1725, 110),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 1225,
    82),
    )
)

internal val Res.string.literature: StringResource
  get() = CommonMainString0.literature

private fun init_literature(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:literature", "literature",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1836, 46),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 1308,
    34),
    )
)

internal val Res.string.log_in: StringResource
  get() = CommonMainString0.log_in

private fun init_log_in(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:log_in", "log_in",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1883, 30),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 1343,
    22),
    )
)

internal val Res.string.login: StringResource
  get() = CommonMainString0.login

private fun init_login(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:login", "login",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2008, 29),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 1424,
    21),
    )
)

internal val Res.string.login_cannot_be_empty: StringResource
  get() = CommonMainString0.login_cannot_be_empty

private fun init_login_cannot_be_empty(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:login_cannot_be_empty", "login_cannot_be_empty",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1914, 93),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 1366,
    57),
    )
)

internal val Res.string.made_for: StringResource
  get() = CommonMainString0.made_for

private fun init_made_for(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:made_for", "made_for",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2038, 76),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 1446,
    44),
    )
)

internal val Res.string.message: StringResource
  get() = CommonMainString0.message

private fun init_message(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:message", "message",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2115, 39),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 1491,
    27),
    )
)

internal val Res.string.movies: StringResource
  get() = CommonMainString0.movies

private fun init_movies(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:movies", "movies",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2155, 30),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 1519,
    22),
    )
)

internal val Res.string.my_string: StringResource
  get() = CommonMainString0.my_string

private fun init_my_string(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:my_string", "my_string",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 1542,
    57),
    )
)

internal val Res.string.name: StringResource
  get() = CommonMainString0.name

private fun init_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:name", "name",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2186, 20),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 1600,
    20),
    )
)

internal val Res.string.nature_and_ecology: StringResource
  get() = CommonMainString0.nature_and_ecology

private fun init_nature_and_ecology(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:nature_and_ecology", "nature_and_ecology",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2207, 74),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 1621,
    50),
    )
)

internal val Res.string.new_Test: StringResource
  get() = CommonMainString0.new_Test

private fun init_new_Test(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:new_Test", "new_Test",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 1672,
    36),
    )
)

internal val Res.string.news: StringResource
  get() = CommonMainString0.news

private fun init_news(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:news", "news",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2282, 32),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 1709,
    20),
    )
)

internal val Res.string.next: StringResource
  get() = CommonMainString0.next

private fun init_next(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:next", "next",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2315, 28),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 1730,
    20),
    )
)

internal val Res.string.no_definitions: StringResource
  get() = CommonMainString0.no_definitions

private fun init_no_definitions(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:no_definitions", "no_definitions",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2344, 102),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 1751,
    66),
    )
)

internal val Res.string.password: StringResource
  get() = CommonMainString0.password

private fun init_password(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:password", "password",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2548, 32),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 1883,
    28),
    )
)

internal val Res.string.password_cannot_be_empty: StringResource
  get() = CommonMainString0.password_cannot_be_empty

private fun init_password_cannot_be_empty(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:password_cannot_be_empty", "password_cannot_be_empty",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2447, 100),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 1818,
    64),
    )
)

internal val Res.string.press_kit: StringResource
  get() = CommonMainString0.press_kit

private fun init_press_kit(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:press_kit", "press_kit",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2581, 41),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 1912,
    29),
    )
)

internal val Res.string.private_police: StringResource
  get() = CommonMainString0.private_police

private fun init_private_police(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:private_police", "private_police",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2623, 94),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 1942,
    42),
    )
)

internal val Res.string.profile: StringResource
  get() = CommonMainString0.profile

private fun init_profile(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:profile", "profile",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2718, 35),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 1985,
    27),
    )
)

internal val Res.string.reset_your_password: StringResource
  get() = CommonMainString0.reset_your_password

private fun init_reset_your_password(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:reset_your_password", "reset_your_password",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2754, 67),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 2013,
    55),
    )
)

internal val Res.string.science_and_education: StringResource
  get() = CommonMainString0.science_and_education

private fun init_science_and_education(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:science_and_education", "science_and_education",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2822, 77),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 2069,
    57),
    )
)

internal val Res.string.search: StringResource
  get() = CommonMainString0.search

private fun init_search(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:search", "search",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2900, 30),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 2127,
    22),
    )
)

internal val Res.string.sign_up: StringResource
  get() = CommonMainString0.sign_up

private fun init_sign_up(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:sign_up", "sign_up",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2931, 63),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 2150,
    27),
    )
)

internal val Res.string.sport: StringResource
  get() = CommonMainString0.sport

private fun init_sport(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:sport", "sport",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2995, 29),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 2178,
    21),
    )
)

internal val Res.string.technologies: StringResource
  get() = CommonMainString0.technologies

private fun init_technologies(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:technologies", "technologies",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3025, 48),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 2200,
    36),
    )
)

internal val Res.string.topics: StringResource
  get() = CommonMainString0.topics

private fun init_topics(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:topics", "topics",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3074, 26),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 2237,
    22),
    )
)

internal val Res.string.trips: StringResource
  get() = CommonMainString0.trips

private fun init_trips(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:trips", "trips",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3101, 45),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 2260,
    21),
    )
)

internal val Res.string.usage_examples: StringResource
  get() = CommonMainString0.usage_examples

private fun init_usage_examples(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:usage_examples", "usage_examples",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3147, 78),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 2282,
    42),
    )
)

internal val Res.string.welcome_back: StringResource
  get() = CommonMainString0.welcome_back

private fun init_welcome_back(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:welcome_back", "welcome_back",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3226, 56),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 2325,
    36),
    )
)

internal val Res.string.whats_new: StringResource
  get() = CommonMainString0.whats_new

private fun init_whats_new(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:whats_new", "whats_new",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3283, 45),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 2362,
    29),
    )
)
