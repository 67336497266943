package org.ailingo.app.composeApp

import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.AfterVersion
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlDriver
import app.cash.sqldelight.db.SqlSchema
import kotlin.Long
import kotlin.Unit
import kotlin.reflect.KClass
import org.ailingo.app.AppDatabase
import org.ailingo.app.HistoryDictionaryQueries
import org.ailingo.app.TokenQueries

internal val KClass<AppDatabase>.schema: SqlSchema<QueryResult.AsyncValue<Unit>>
  get() = AppDatabaseImpl.Schema

internal fun KClass<AppDatabase>.newInstance(driver: SqlDriver): AppDatabase =
    AppDatabaseImpl(driver)

private class AppDatabaseImpl(
  driver: SqlDriver,
) : SuspendingTransacterImpl(driver), AppDatabase {
  override val historyDictionaryQueries: HistoryDictionaryQueries = HistoryDictionaryQueries(driver)

  override val tokenQueries: TokenQueries = TokenQueries(driver)

  public object Schema : SqlSchema<QueryResult.AsyncValue<Unit>> {
    override val version: Long
      get() = 1

    override fun create(driver: SqlDriver): QueryResult.AsyncValue<Unit> = QueryResult.AsyncValue {
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS HistoryDictionaryEntity(
          |    id INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT,
          |    text TEXT NOT NULL
          |)
          """.trimMargin(), 0).await()
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS Token (
          |    id INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT,
          |    token TEXT NOT NULL UNIQUE,
          |    refresh_token TEXT NOT NULL UNIQUE
          |)
          """.trimMargin(), 0).await()
    }

    override fun migrate(
      driver: SqlDriver,
      oldVersion: Long,
      newVersion: Long,
      vararg callbacks: AfterVersion,
    ): QueryResult.AsyncValue<Unit> = QueryResult.AsyncValue {
    }
  }
}
