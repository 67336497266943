package org.ailingo.app

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import kotlin.Any
import kotlin.Long
import kotlin.String

public class HistoryDictionaryQueries(
  driver: SqlDriver,
) : SuspendingTransacterImpl(driver) {
  public fun <T : Any> getDictionaryHistory(mapper: (id: Long, text: String) -> T): Query<T> =
      Query(957_123_456, arrayOf("HistoryDictionaryEntity"), driver, "HistoryDictionary.sq",
      "getDictionaryHistory",
      "SELECT HistoryDictionaryEntity.id, HistoryDictionaryEntity.text FROM HistoryDictionaryEntity") {
      cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getString(1)!!
    )
  }

  public fun getDictionaryHistory(): Query<HistoryDictionaryEntity> = getDictionaryHistory { id,
      text ->
    HistoryDictionaryEntity(
      id,
      text
    )
  }

  public fun <T : Any> getDictionaryHistoryById(id: Long, mapper: (id: Long, text: String) -> T):
      Query<T> = GetDictionaryHistoryByIdQuery(id) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getString(1)!!
    )
  }

  public fun getDictionaryHistoryById(id: Long): Query<HistoryDictionaryEntity> =
      getDictionaryHistoryById(id) { id_, text ->
    HistoryDictionaryEntity(
      id_,
      text
    )
  }

  public suspend fun insertDictionaryHistory(id: Long?, text: String) {
    driver.execute(-1_925_277_363, """
        |INSERT OR REPLACE
        |INTO HistoryDictionaryEntity(
        |    id,text
        |) VALUES (?,?)
        """.trimMargin(), 2) {
          bindLong(0, id)
          bindString(1, text)
        }.await()
    notifyQueries(-1_925_277_363) { emit ->
      emit("HistoryDictionaryEntity")
    }
  }

  public suspend fun deleteFromDictionaryHistory(id: Long) {
    driver.execute(-1_473_576_047, """DELETE FROM HistoryDictionaryEntity WHERE id = ?""", 1) {
          bindLong(0, id)
        }.await()
    notifyQueries(-1_473_576_047) { emit ->
      emit("HistoryDictionaryEntity")
    }
  }

  private inner class GetDictionaryHistoryByIdQuery<out T : Any>(
    public val id: Long,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("HistoryDictionaryEntity", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("HistoryDictionaryEntity", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(-2_131_059_854,
        """SELECT HistoryDictionaryEntity.id, HistoryDictionaryEntity.text FROM HistoryDictionaryEntity WHERE id = ?""",
        mapper, 1) {
      bindLong(0, id)
    }

    override fun toString(): String = "HistoryDictionary.sq:getDictionaryHistoryById"
  }
}
