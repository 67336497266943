@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package ailingo.composeapp.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.InternalResourceApi

private object CommonMainDrawable0 {
  public val ArrowBackIOS: DrawableResource by 
      lazy { init_ArrowBackIOS() }

  public val ArrowForwardIOS: DrawableResource by 
      lazy { init_ArrowForwardIOS() }

  public val ailingologowithoutbackground: DrawableResource by 
      lazy { init_ailingologowithoutbackground() }

  public val coins: DrawableResource by 
      lazy { init_coins() }

  public val defaultProfilePhoto: DrawableResource by 
      lazy { init_defaultProfilePhoto() }

  public val emptyscreen: DrawableResource by 
      lazy { init_emptyscreen() }

  public val googlePlay: DrawableResource by 
      lazy { init_googlePlay() }

  public val icon_experience: DrawableResource by 
      lazy { init_icon_experience() }

  public val logo: DrawableResource by 
      lazy { init_logo() }

  public val profile_background: DrawableResource by 
      lazy { init_profile_background() }

  public val streak: DrawableResource by 
      lazy { init_streak() }

  public val visibility: DrawableResource by 
      lazy { init_visibility() }

  public val visibility_off: DrawableResource by 
      lazy { init_visibility_off() }
}

@InternalResourceApi
internal fun _collectCommonMainDrawable0Resources(map: MutableMap<String, DrawableResource>) {
  map.put("ArrowBackIOS", CommonMainDrawable0.ArrowBackIOS)
  map.put("ArrowForwardIOS", CommonMainDrawable0.ArrowForwardIOS)
  map.put("ailingologowithoutbackground", CommonMainDrawable0.ailingologowithoutbackground)
  map.put("coins", CommonMainDrawable0.coins)
  map.put("defaultProfilePhoto", CommonMainDrawable0.defaultProfilePhoto)
  map.put("emptyscreen", CommonMainDrawable0.emptyscreen)
  map.put("googlePlay", CommonMainDrawable0.googlePlay)
  map.put("icon_experience", CommonMainDrawable0.icon_experience)
  map.put("logo", CommonMainDrawable0.logo)
  map.put("profile_background", CommonMainDrawable0.profile_background)
  map.put("streak", CommonMainDrawable0.streak)
  map.put("visibility", CommonMainDrawable0.visibility)
  map.put("visibility_off", CommonMainDrawable0.visibility_off)
}

internal val Res.drawable.ArrowBackIOS: DrawableResource
  get() = CommonMainDrawable0.ArrowBackIOS

private fun init_ArrowBackIOS(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ArrowBackIOS",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/drawable/ArrowBackIOS.png", -1, -1),
    )
)

internal val Res.drawable.ArrowForwardIOS: DrawableResource
  get() = CommonMainDrawable0.ArrowForwardIOS

private fun init_ArrowForwardIOS(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ArrowForwardIOS",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/drawable/ArrowForwardIOS.png", -1, -1),
    )
)

internal val Res.drawable.ailingologowithoutbackground: DrawableResource
  get() = CommonMainDrawable0.ailingologowithoutbackground

private fun init_ailingologowithoutbackground(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ailingologowithoutbackground",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/drawable/ailingologowithoutbackground.png", -1, -1),
    )
)

internal val Res.drawable.coins: DrawableResource
  get() = CommonMainDrawable0.coins

private fun init_coins(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:coins",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/drawable/coins.png", -1, -1),
    )
)

internal val Res.drawable.defaultProfilePhoto: DrawableResource
  get() = CommonMainDrawable0.defaultProfilePhoto

private fun init_defaultProfilePhoto(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:defaultProfilePhoto",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/drawable/defaultProfilePhoto.png", -1, -1),
    )
)

internal val Res.drawable.emptyscreen: DrawableResource
  get() = CommonMainDrawable0.emptyscreen

private fun init_emptyscreen(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:emptyscreen",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/drawable/emptyscreen.png", -1, -1),
    )
)

internal val Res.drawable.googlePlay: DrawableResource
  get() = CommonMainDrawable0.googlePlay

private fun init_googlePlay(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:googlePlay",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/drawable/googlePlay.png", -1, -1),
    )
)

internal val Res.drawable.icon_experience: DrawableResource
  get() = CommonMainDrawable0.icon_experience

private fun init_icon_experience(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:icon_experience",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/drawable/icon-experience.png", -1, -1),
    )
)

internal val Res.drawable.logo: DrawableResource
  get() = CommonMainDrawable0.logo

private fun init_logo(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/drawable/logo.png", -1, -1),
    )
)

internal val Res.drawable.profile_background: DrawableResource
  get() = CommonMainDrawable0.profile_background

private fun init_profile_background(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:profile_background",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/drawable/profile-background.jpeg", -1, -1),
    )
)

internal val Res.drawable.streak: DrawableResource
  get() = CommonMainDrawable0.streak

private fun init_streak(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:streak",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/drawable/streak.png", -1, -1),
    )
)

internal val Res.drawable.visibility: DrawableResource
  get() = CommonMainDrawable0.visibility

private fun init_visibility(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:visibility",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/drawable/visibility.xml", -1, -1),
    )
)

internal val Res.drawable.visibility_off: DrawableResource
  get() = CommonMainDrawable0.visibility_off

private fun init_visibility_off(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:visibility_off",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/drawable/visibility_off.xml", -1, -1),
    )
)
