@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package ailingo.composeapp.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.InternalResourceApi
import org.jetbrains.compose.resources.StringResource

private object CommonMainString0 {
  public val already_have_account: StringResource by 
      lazy { init_already_have_account() }

  public val already_have_an_account: StringResource by 
      lazy { init_already_have_an_account() }

  public val app_name: StringResource by 
      lazy { init_app_name() }

  public val app_name_underspace: StringResource by 
      lazy { init_app_name_underspace() }

  public val avatar: StringResource by 
      lazy { init_avatar() }

  public val back: StringResource by 
      lazy { init_back() }

  public val back_input_fields: StringResource by 
      lazy { init_back_input_fields() }

  public val back_to_the_selection: StringResource by 
      lazy { init_back_to_the_selection() }

  public val bot_greeting_topic1: StringResource by 
      lazy { init_bot_greeting_topic1() }

  public val bot_greeting_topic2: StringResource by 
      lazy { init_bot_greeting_topic2() }

  public val bot_greeting_topic3: StringResource by 
      lazy { init_bot_greeting_topic3() }

  public val bot_greeting_topic4: StringResource by 
      lazy { init_bot_greeting_topic4() }

  public val business: StringResource by 
      lazy { init_business() }

  public val cartoons: StringResource by 
      lazy { init_cartoons() }

  public val change_of_personal_data: StringResource by 
      lazy { init_change_of_personal_data() }

  public val change_user_data: StringResource by 
      lazy { init_change_user_data() }

  public val choose_image: StringResource by 
      lazy { init_choose_image() }

  public val coins: StringResource by 
      lazy { init_coins() }

  public val connection_timeout: StringResource by 
      lazy { init_connection_timeout() }

  public val continue_app: StringResource by 
      lazy { init_continue_app() }

  public val continue_with_default_image: StringResource by 
      lazy { init_continue_with_default_image() }

  public val could_not_connect: StringResource by 
      lazy { init_could_not_connect() }

  public val create_your_account: StringResource by 
      lazy { init_create_your_account() }

  public val culture_and_art: StringResource by 
      lazy { init_culture_and_art() }

  public val current_password: StringResource by 
      lazy { init_current_password() }

  public val data_changed_successfully: StringResource by 
      lazy { init_data_changed_successfully() }

  public val definitions: StringResource by 
      lazy { init_definitions() }

  public val delete_avatar: StringResource by 
      lazy { init_delete_avatar() }

  public val dictionary: StringResource by 
      lazy { init_dictionary() }

  public val dont_have_an_account: StringResource by 
      lazy { init_dont_have_an_account() }

  public val email: StringResource by 
      lazy { init_email() }

  public val email_invalid: StringResource by 
      lazy { init_email_invalid() }

  public val empty_favourite_words: StringResource by 
      lazy { init_empty_favourite_words() }

  public val enter_current_password: StringResource by 
      lazy { init_enter_current_password() }

  public val enter_password: StringResource by 
      lazy { init_enter_password() }

  public val enter_your_email: StringResource by 
      lazy { init_enter_your_email() }

  public val enter_your_login: StringResource by 
      lazy { init_enter_your_login() }

  public val enter_your_name: StringResource by 
      lazy { init_enter_your_name() }

  public val enter_your_new_password: StringResource by 
      lazy { init_enter_your_new_password() }

  public val error: StringResource by 
      lazy { init_error() }

  public val exit: StringResource by 
      lazy { init_exit() }

  public val fashion_and_style: StringResource by 
      lazy { init_fashion_and_style() }

  public val favourite_words: StringResource by 
      lazy { init_favourite_words() }

  public val food_and_drinks: StringResource by 
      lazy { init_food_and_drinks() }

  public val forgot_password: StringResource by 
      lazy { init_forgot_password() }

  public val free_mode: StringResource by 
      lazy { init_free_mode() }

  public val get_started: StringResource by 
      lazy { init_get_started() }

  public val health_and_medicine: StringResource by 
      lazy { init_health_and_medicine() }

  public val here_is_empty: StringResource by 
      lazy { init_here_is_empty() }

  public val history: StringResource by 
      lazy { init_history() }

  public val history_of_search: StringResource by 
      lazy { init_history_of_search() }

  public val invalid_email_format: StringResource by 
      lazy { init_invalid_email_format() }

  public val lets_add_your_avatar: StringResource by 
      lazy { init_lets_add_your_avatar() }

  public val lets_other_get_to_know_you: StringResource by 
      lazy { init_lets_other_get_to_know_you() }

  public val literature: StringResource by 
      lazy { init_literature() }

  public val log_in: StringResource by 
      lazy { init_log_in() }

  public val login: StringResource by 
      lazy { init_login() }

  public val login_cannot_be_empty: StringResource by 
      lazy { init_login_cannot_be_empty() }

  public val login_invalid: StringResource by 
      lazy { init_login_invalid() }

  public val login_must_be_between: StringResource by 
      lazy { init_login_must_be_between() }

  public val login_to_countinue: StringResource by 
      lazy { init_login_to_countinue() }

  public val made_for: StringResource by 
      lazy { init_made_for() }

  public val message: StringResource by 
      lazy { init_message() }

  public val movies: StringResource by 
      lazy { init_movies() }

  public val my_string: StringResource by 
      lazy { init_my_string() }

  public val name: StringResource by 
      lazy { init_name() }

  public val name_cannot_be_blank: StringResource by 
      lazy { init_name_cannot_be_blank() }

  public val name_invalid: StringResource by 
      lazy { init_name_invalid() }

  public val nature_and_ecology: StringResource by 
      lazy { init_nature_and_ecology() }

  public val new_Test: StringResource by 
      lazy { init_new_Test() }

  public val new_password: StringResource by 
      lazy { init_new_password() }

  public val news: StringResource by 
      lazy { init_news() }

  public val next: StringResource by 
      lazy { init_next() }

  public val no_definitions: StringResource by 
      lazy { init_no_definitions() }

  public val password: StringResource by 
      lazy { init_password() }

  public val password_cannot_be_empty: StringResource by 
      lazy { init_password_cannot_be_empty() }

  public val password_invalid: StringResource by 
      lazy { init_password_invalid() }

  public val password_must_be_between: StringResource by 
      lazy { init_password_must_be_between() }

  public val press_kit: StringResource by 
      lazy { init_press_kit() }

  public val private_police: StringResource by 
      lazy { init_private_police() }

  public val profile: StringResource by 
      lazy { init_profile() }

  public val register: StringResource by 
      lazy { init_register() }

  public val register_subtitle: StringResource by 
      lazy { init_register_subtitle() }

  public val register_title: StringResource by 
      lazy { init_register_title() }

  public val request_failed: StringResource by 
      lazy { init_request_failed() }

  public val request_timeout: StringResource by 
      lazy { init_request_timeout() }

  public val reset_your_password: StringResource by 
      lazy { init_reset_your_password() }

  public val science_and_education: StringResource by 
      lazy { init_science_and_education() }

  public val search: StringResource by 
      lazy { init_search() }

  public val sign_up: StringResource by 
      lazy { init_sign_up() }

  public val sport: StringResource by 
      lazy { init_sport() }

  public val streak: StringResource by 
      lazy { init_streak() }

  public val technologies: StringResource by 
      lazy { init_technologies() }

  public val topic_list_empty: StringResource by 
      lazy { init_topic_list_empty() }

  public val topics: StringResource by 
      lazy { init_topics() }

  public val trips: StringResource by 
      lazy { init_trips() }

  public val unexpected_error: StringResource by 
      lazy { init_unexpected_error() }

  public val usage_examples: StringResource by 
      lazy { init_usage_examples() }

  public val username: StringResource by 
      lazy { init_username() }

  public val welcome_back: StringResource by 
      lazy { init_welcome_back() }

  public val whats_new: StringResource by 
      lazy { init_whats_new() }

  public val word_not_found: StringResource by 
      lazy { init_word_not_found() }

  public val wrong_login_or_password: StringResource by 
      lazy { init_wrong_login_or_password() }

  public val xp: StringResource by 
      lazy { init_xp() }

  public val your_statistics: StringResource by 
      lazy { init_your_statistics() }
}

@InternalResourceApi
internal fun _collectCommonMainString0Resources(map: MutableMap<String, StringResource>) {
  map.put("already_have_account", CommonMainString0.already_have_account)
  map.put("already_have_an_account", CommonMainString0.already_have_an_account)
  map.put("app_name", CommonMainString0.app_name)
  map.put("app_name_underspace", CommonMainString0.app_name_underspace)
  map.put("avatar", CommonMainString0.avatar)
  map.put("back", CommonMainString0.back)
  map.put("back_input_fields", CommonMainString0.back_input_fields)
  map.put("back_to_the_selection", CommonMainString0.back_to_the_selection)
  map.put("bot_greeting_topic1", CommonMainString0.bot_greeting_topic1)
  map.put("bot_greeting_topic2", CommonMainString0.bot_greeting_topic2)
  map.put("bot_greeting_topic3", CommonMainString0.bot_greeting_topic3)
  map.put("bot_greeting_topic4", CommonMainString0.bot_greeting_topic4)
  map.put("business", CommonMainString0.business)
  map.put("cartoons", CommonMainString0.cartoons)
  map.put("change_of_personal_data", CommonMainString0.change_of_personal_data)
  map.put("change_user_data", CommonMainString0.change_user_data)
  map.put("choose_image", CommonMainString0.choose_image)
  map.put("coins", CommonMainString0.coins)
  map.put("connection_timeout", CommonMainString0.connection_timeout)
  map.put("continue_app", CommonMainString0.continue_app)
  map.put("continue_with_default_image", CommonMainString0.continue_with_default_image)
  map.put("could_not_connect", CommonMainString0.could_not_connect)
  map.put("create_your_account", CommonMainString0.create_your_account)
  map.put("culture_and_art", CommonMainString0.culture_and_art)
  map.put("current_password", CommonMainString0.current_password)
  map.put("data_changed_successfully", CommonMainString0.data_changed_successfully)
  map.put("definitions", CommonMainString0.definitions)
  map.put("delete_avatar", CommonMainString0.delete_avatar)
  map.put("dictionary", CommonMainString0.dictionary)
  map.put("dont_have_an_account", CommonMainString0.dont_have_an_account)
  map.put("email", CommonMainString0.email)
  map.put("email_invalid", CommonMainString0.email_invalid)
  map.put("empty_favourite_words", CommonMainString0.empty_favourite_words)
  map.put("enter_current_password", CommonMainString0.enter_current_password)
  map.put("enter_password", CommonMainString0.enter_password)
  map.put("enter_your_email", CommonMainString0.enter_your_email)
  map.put("enter_your_login", CommonMainString0.enter_your_login)
  map.put("enter_your_name", CommonMainString0.enter_your_name)
  map.put("enter_your_new_password", CommonMainString0.enter_your_new_password)
  map.put("error", CommonMainString0.error)
  map.put("exit", CommonMainString0.exit)
  map.put("fashion_and_style", CommonMainString0.fashion_and_style)
  map.put("favourite_words", CommonMainString0.favourite_words)
  map.put("food_and_drinks", CommonMainString0.food_and_drinks)
  map.put("forgot_password", CommonMainString0.forgot_password)
  map.put("free_mode", CommonMainString0.free_mode)
  map.put("get_started", CommonMainString0.get_started)
  map.put("health_and_medicine", CommonMainString0.health_and_medicine)
  map.put("here_is_empty", CommonMainString0.here_is_empty)
  map.put("history", CommonMainString0.history)
  map.put("history_of_search", CommonMainString0.history_of_search)
  map.put("invalid_email_format", CommonMainString0.invalid_email_format)
  map.put("lets_add_your_avatar", CommonMainString0.lets_add_your_avatar)
  map.put("lets_other_get_to_know_you", CommonMainString0.lets_other_get_to_know_you)
  map.put("literature", CommonMainString0.literature)
  map.put("log_in", CommonMainString0.log_in)
  map.put("login", CommonMainString0.login)
  map.put("login_cannot_be_empty", CommonMainString0.login_cannot_be_empty)
  map.put("login_invalid", CommonMainString0.login_invalid)
  map.put("login_must_be_between", CommonMainString0.login_must_be_between)
  map.put("login_to_countinue", CommonMainString0.login_to_countinue)
  map.put("made_for", CommonMainString0.made_for)
  map.put("message", CommonMainString0.message)
  map.put("movies", CommonMainString0.movies)
  map.put("my_string", CommonMainString0.my_string)
  map.put("name", CommonMainString0.name)
  map.put("name_cannot_be_blank", CommonMainString0.name_cannot_be_blank)
  map.put("name_invalid", CommonMainString0.name_invalid)
  map.put("nature_and_ecology", CommonMainString0.nature_and_ecology)
  map.put("new_Test", CommonMainString0.new_Test)
  map.put("new_password", CommonMainString0.new_password)
  map.put("news", CommonMainString0.news)
  map.put("next", CommonMainString0.next)
  map.put("no_definitions", CommonMainString0.no_definitions)
  map.put("password", CommonMainString0.password)
  map.put("password_cannot_be_empty", CommonMainString0.password_cannot_be_empty)
  map.put("password_invalid", CommonMainString0.password_invalid)
  map.put("password_must_be_between", CommonMainString0.password_must_be_between)
  map.put("press_kit", CommonMainString0.press_kit)
  map.put("private_police", CommonMainString0.private_police)
  map.put("profile", CommonMainString0.profile)
  map.put("register", CommonMainString0.register)
  map.put("register_subtitle", CommonMainString0.register_subtitle)
  map.put("register_title", CommonMainString0.register_title)
  map.put("request_failed", CommonMainString0.request_failed)
  map.put("request_timeout", CommonMainString0.request_timeout)
  map.put("reset_your_password", CommonMainString0.reset_your_password)
  map.put("science_and_education", CommonMainString0.science_and_education)
  map.put("search", CommonMainString0.search)
  map.put("sign_up", CommonMainString0.sign_up)
  map.put("sport", CommonMainString0.sport)
  map.put("streak", CommonMainString0.streak)
  map.put("technologies", CommonMainString0.technologies)
  map.put("topic_list_empty", CommonMainString0.topic_list_empty)
  map.put("topics", CommonMainString0.topics)
  map.put("trips", CommonMainString0.trips)
  map.put("unexpected_error", CommonMainString0.unexpected_error)
  map.put("usage_examples", CommonMainString0.usage_examples)
  map.put("username", CommonMainString0.username)
  map.put("welcome_back", CommonMainString0.welcome_back)
  map.put("whats_new", CommonMainString0.whats_new)
  map.put("word_not_found", CommonMainString0.word_not_found)
  map.put("wrong_login_or_password", CommonMainString0.wrong_login_or_password)
  map.put("xp", CommonMainString0.xp)
  map.put("your_statistics", CommonMainString0.your_statistics)
}

internal val Res.string.already_have_account: StringResource
  get() = CommonMainString0.already_have_account

private fun init_already_have_account(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:already_have_account", "already_have_account",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    10, 84),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 10,
    56),
    )
)

internal val Res.string.already_have_an_account: StringResource
  get() = CommonMainString0.already_have_an_account

private fun init_already_have_an_account(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:already_have_an_account", "already_have_an_account",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    95, 75),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 67,
    63),
    )
)

internal val Res.string.app_name: StringResource
  get() = CommonMainString0.app_name

private fun init_app_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:app_name", "app_name",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    211, 28),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 171,
    28),
    )
)

internal val Res.string.app_name_underspace: StringResource
  get() = CommonMainString0.app_name_underspace

private fun init_app_name_underspace(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:app_name_underspace", "app_name_underspace",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    171, 39),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 131,
    39),
    )
)

internal val Res.string.avatar: StringResource
  get() = CommonMainString0.avatar

private fun init_avatar(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:avatar", "avatar",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    240, 30),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 200,
    22),
    )
)

internal val Res.string.back: StringResource
  get() = CommonMainString0.back

private fun init_back(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:back", "back",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    435, 28),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 339,
    20),
    )
)

internal val Res.string.back_input_fields: StringResource
  get() = CommonMainString0.back_input_fields

private fun init_back_input_fields(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:back_input_fields", "back_input_fields",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    271, 85),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 223,
    57),
    )
)

internal val Res.string.back_to_the_selection: StringResource
  get() = CommonMainString0.back_to_the_selection

private fun init_back_to_the_selection(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:back_to_the_selection", "back_to_the_selection",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    357, 77),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 281,
    57),
    )
)

internal val Res.string.bot_greeting_topic1: StringResource
  get() = CommonMainString0.bot_greeting_topic1

private fun init_bot_greeting_topic1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:bot_greeting_topic1", "bot_greeting_topic1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    464, 187),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 360,
    135),
    )
)

internal val Res.string.bot_greeting_topic2: StringResource
  get() = CommonMainString0.bot_greeting_topic2

private fun init_bot_greeting_topic2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:bot_greeting_topic2", "bot_greeting_topic2",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    652, 223),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 496,
    131),
    )
)

internal val Res.string.bot_greeting_topic3: StringResource
  get() = CommonMainString0.bot_greeting_topic3

private fun init_bot_greeting_topic3(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:bot_greeting_topic3", "bot_greeting_topic3",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    876, 191),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 628,
    127),
    )
)

internal val Res.string.bot_greeting_topic4: StringResource
  get() = CommonMainString0.bot_greeting_topic4

private fun init_bot_greeting_topic4(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:bot_greeting_topic4", "bot_greeting_topic4",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1068, 195),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 756,
    119),
    )
)

internal val Res.string.business: StringResource
  get() = CommonMainString0.business

private fun init_business(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:business", "business",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1264, 32),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 876,
    28),
    )
)

internal val Res.string.cartoons: StringResource
  get() = CommonMainString0.cartoons

private fun init_cartoons(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:cartoons", "cartoons",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1297, 48),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 905,
    28),
    )
)

internal val Res.string.change_of_personal_data: StringResource
  get() = CommonMainString0.change_of_personal_data

private fun init_change_of_personal_data(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:change_of_personal_data", "change_of_personal_data",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1346, 75),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 934,
    47),
    )
)

internal val Res.string.change_user_data: StringResource
  get() = CommonMainString0.change_user_data

private fun init_change_user_data(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:change_user_data", "change_user_data",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1422, 64),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 982,
    40),
    )
)

internal val Res.string.choose_image: StringResource
  get() = CommonMainString0.choose_image

private fun init_choose_image(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:choose_image", "choose_image",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1487, 72),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 1023,
    36),
    )
)

internal val Res.string.coins: StringResource
  get() = CommonMainString0.coins

private fun init_coins(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:coins", "coins",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1560, 29),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 1060,
    21),
    )
)

internal val Res.string.connection_timeout: StringResource
  get() = CommonMainString0.connection_timeout

private fun init_connection_timeout(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:connection_timeout", "connection_timeout",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1590, 282),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 1082,
    130),
    )
)

internal val Res.string.continue_app: StringResource
  get() = CommonMainString0.continue_app

private fun init_continue_app(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:continue_app", "continue_app",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1873, 48),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 1213,
    32),
    )
)

internal val Res.string.continue_with_default_image: StringResource
  get() = CommonMainString0.continue_with_default_image

private fun init_continue_with_default_image(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:continue_with_default_image", "continue_with_default_image",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    1922, 143),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 1246,
    75),
    )
)

internal val Res.string.could_not_connect: StringResource
  get() = CommonMainString0.could_not_connect

private fun init_could_not_connect(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:could_not_connect", "could_not_connect",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2066, 225),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 1322,
    121),
    )
)

internal val Res.string.create_your_account: StringResource
  get() = CommonMainString0.create_your_account

private fun init_create_your_account(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:create_your_account", "create_your_account",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2292, 83),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 1444,
    55),
    )
)

internal val Res.string.culture_and_art: StringResource
  get() = CommonMainString0.culture_and_art

private fun init_culture_and_art(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:culture_and_art", "culture_and_art",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2376, 75),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 1500,
    43),
    )
)

internal val Res.string.current_password: StringResource
  get() = CommonMainString0.current_password

private fun init_current_password(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:current_password", "current_password",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2452, 96),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 1544,
    64),
    )
)

internal val Res.string.data_changed_successfully: StringResource
  get() = CommonMainString0.data_changed_successfully

private fun init_data_changed_successfully(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:data_changed_successfully", "data_changed_successfully",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2549, 93),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 1609,
    69),
    )
)

internal val Res.string.definitions: StringResource
  get() = CommonMainString0.definitions

private fun init_definitions(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:definitions", "definitions",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2643, 51),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 1679,
    35),
    )
)

internal val Res.string.delete_avatar: StringResource
  get() = CommonMainString0.delete_avatar

private fun init_delete_avatar(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:delete_avatar", "delete_avatar",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2695, 57),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 1715,
    41),
    )
)

internal val Res.string.dictionary: StringResource
  get() = CommonMainString0.dictionary

private fun init_dictionary(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:dictionary", "dictionary",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2753, 38),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 1757,
    34),
    )
)

internal val Res.string.dont_have_an_account: StringResource
  get() = CommonMainString0.dont_have_an_account

private fun init_dont_have_an_account(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:dont_have_an_account", "dont_have_an_account",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2792, 60),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 1792,
    56),
    )
)

internal val Res.string.email: StringResource
  get() = CommonMainString0.email

private fun init_email(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:email", "email",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2959, 57),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 1899,
    21),
    )
)

internal val Res.string.email_invalid: StringResource
  get() = CommonMainString0.email_invalid

private fun init_email_invalid(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:email_invalid", "email_invalid",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    2853, 105),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 1849,
    49),
    )
)

internal val Res.string.empty_favourite_words: StringResource
  get() = CommonMainString0.empty_favourite_words

private fun init_empty_favourite_words(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:empty_favourite_words", "empty_favourite_words",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3017, 97),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 1921,
    77),
    )
)

internal val Res.string.enter_current_password: StringResource
  get() = CommonMainString0.enter_current_password

private fun init_enter_current_password(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:enter_current_password", "enter_current_password",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3115, 86),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 1999,
    62),
    )
)

internal val Res.string.enter_password: StringResource
  get() = CommonMainString0.enter_password

private fun init_enter_password(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:enter_password", "enter_password",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3202, 58),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 2062,
    42),
    )
)

internal val Res.string.enter_your_email: StringResource
  get() = CommonMainString0.enter_your_email

private fun init_enter_your_email(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:enter_your_email", "enter_your_email",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3261, 60),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 2105,
    48),
    )
)

internal val Res.string.enter_your_login: StringResource
  get() = CommonMainString0.enter_your_login

private fun init_enter_your_login(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:enter_your_login", "enter_your_login",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3322, 68),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 2154,
    48),
    )
)

internal val Res.string.enter_your_name: StringResource
  get() = CommonMainString0.enter_your_name

private fun init_enter_your_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:enter_your_name", "enter_your_name",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3391, 63),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 2203,
    43),
    )
)

internal val Res.string.enter_your_new_password: StringResource
  get() = CommonMainString0.enter_your_new_password

private fun init_enter_your_new_password(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:enter_your_new_password", "enter_your_new_password",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3455, 83),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 2247,
    55),
    )
)

internal val Res.string.error: StringResource
  get() = CommonMainString0.error

private fun init_error(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:error", "error",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3539, 29),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 2303,
    21),
    )
)

internal val Res.string.exit: StringResource
  get() = CommonMainString0.exit

private fun init_exit(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:exit", "exit",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3569, 28),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 2325,
    20),
    )
)

internal val Res.string.fashion_and_style: StringResource
  get() = CommonMainString0.fashion_and_style

private fun init_fashion_and_style(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fashion_and_style", "fashion_and_style",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3598, 57),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 2346,
    49),
    )
)

internal val Res.string.favourite_words: StringResource
  get() = CommonMainString0.favourite_words

private fun init_favourite_words(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:favourite_words", "favourite_words",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3656, 63),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 2396,
    43),
    )
)

internal val Res.string.food_and_drinks: StringResource
  get() = CommonMainString0.food_and_drinks

private fun init_food_and_drinks(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:food_and_drinks", "food_and_drinks",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3720, 55),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 2440,
    43),
    )
)

internal val Res.string.forgot_password: StringResource
  get() = CommonMainString0.forgot_password

private fun init_forgot_password(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:forgot_password", "forgot_password",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3776, 59),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 2484,
    47),
    )
)

internal val Res.string.free_mode: StringResource
  get() = CommonMainString0.free_mode

private fun init_free_mode(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:free_mode", "free_mode",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3836, 25),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 2532,
    29),
    )
)

internal val Res.string.get_started: StringResource
  get() = CommonMainString0.get_started

private fun init_get_started(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:get_started", "get_started",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3862, 35),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 2562,
    35),
    )
)

internal val Res.string.health_and_medicine: StringResource
  get() = CommonMainString0.health_and_medicine

private fun init_health_and_medicine(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:health_and_medicine", "health_and_medicine",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3898, 75),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 2598,
    55),
    )
)

internal val Res.string.here_is_empty: StringResource
  get() = CommonMainString0.here_is_empty

private fun init_here_is_empty(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:here_is_empty", "here_is_empty",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    3974, 49),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 2654,
    41),
    )
)

internal val Res.string.history: StringResource
  get() = CommonMainString0.history

private fun init_history(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:history", "history",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    4134, 35),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 2782,
    27),
    )
)

internal val Res.string.history_of_search: StringResource
  get() = CommonMainString0.history_of_search

private fun init_history_of_search(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:history_of_search", "history_of_search",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    4024, 109),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 2696,
    85),
    )
)

internal val Res.string.invalid_email_format: StringResource
  get() = CommonMainString0.invalid_email_format

private fun init_invalid_email_format(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:invalid_email_format", "invalid_email_format",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    4170, 112),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 2810,
    56),
    )
)

internal val Res.string.lets_add_your_avatar: StringResource
  get() = CommonMainString0.lets_add_your_avatar

private fun init_lets_add_your_avatar(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:lets_add_your_avatar", "lets_add_your_avatar",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    4283, 140),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 2867,
    68),
    )
)

internal val Res.string.lets_other_get_to_know_you: StringResource
  get() = CommonMainString0.lets_other_get_to_know_you

private fun init_lets_other_get_to_know_you(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:lets_other_get_to_know_you", "lets_other_get_to_know_you",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    4424, 110),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 2936,
    82),
    )
)

internal val Res.string.literature: StringResource
  get() = CommonMainString0.literature

private fun init_literature(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:literature", "literature",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    4535, 46),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 3019,
    34),
    )
)

internal val Res.string.log_in: StringResource
  get() = CommonMainString0.log_in

private fun init_log_in(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:log_in", "log_in",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    4582, 30),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 3054,
    22),
    )
)

internal val Res.string.login: StringResource
  get() = CommonMainString0.login

private fun init_login(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:login", "login",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    5082, 29),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 3358,
    21),
    )
)

internal val Res.string.login_cannot_be_empty: StringResource
  get() = CommonMainString0.login_cannot_be_empty

private fun init_login_cannot_be_empty(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:login_cannot_be_empty", "login_cannot_be_empty",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    4613, 93),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 3077,
    57),
    )
)

internal val Res.string.login_invalid: StringResource
  get() = CommonMainString0.login_invalid

private fun init_login_invalid(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:login_invalid", "login_invalid",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    4707, 117),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 3135,
    65),
    )
)

internal val Res.string.login_must_be_between: StringResource
  get() = CommonMainString0.login_must_be_between

private fun init_login_must_be_between(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:login_must_be_between", "login_must_be_between",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    4825, 129),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 3201,
    85),
    )
)

internal val Res.string.login_to_countinue: StringResource
  get() = CommonMainString0.login_to_countinue

private fun init_login_to_countinue(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:login_to_countinue", "login_to_countinue",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    4955, 126),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 3287,
    70),
    )
)

internal val Res.string.made_for: StringResource
  get() = CommonMainString0.made_for

private fun init_made_for(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:made_for", "made_for",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    5112, 76),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 3380,
    44),
    )
)

internal val Res.string.message: StringResource
  get() = CommonMainString0.message

private fun init_message(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:message", "message",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    5189, 39),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 3425,
    27),
    )
)

internal val Res.string.movies: StringResource
  get() = CommonMainString0.movies

private fun init_movies(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:movies", "movies",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    5229, 30),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 3453,
    22),
    )
)

internal val Res.string.my_string: StringResource
  get() = CommonMainString0.my_string

private fun init_my_string(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:my_string", "my_string",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 3476,
    57),
    )
)

internal val Res.string.name: StringResource
  get() = CommonMainString0.name

private fun init_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:name", "name",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    5466, 20),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 3664,
    20),
    )
)

internal val Res.string.name_cannot_be_blank: StringResource
  get() = CommonMainString0.name_cannot_be_blank

private fun init_name_cannot_be_blank(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:name_cannot_be_blank", "name_cannot_be_blank",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    5260, 88),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 3534,
    56),
    )
)

internal val Res.string.name_invalid: StringResource
  get() = CommonMainString0.name_invalid

private fun init_name_invalid(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:name_invalid", "name_invalid",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    5349, 116),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 3591,
    72),
    )
)

internal val Res.string.nature_and_ecology: StringResource
  get() = CommonMainString0.nature_and_ecology

private fun init_nature_and_ecology(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:nature_and_ecology", "nature_and_ecology",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    5487, 74),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 3685,
    50),
    )
)

internal val Res.string.new_Test: StringResource
  get() = CommonMainString0.new_Test

private fun init_new_Test(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:new_Test", "new_Test",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 3736,
    36),
    )
)

internal val Res.string.new_password: StringResource
  get() = CommonMainString0.new_password

private fun init_new_password(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:new_password", "new_password",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    5562, 52),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 3773,
    36),
    )
)

internal val Res.string.news: StringResource
  get() = CommonMainString0.news

private fun init_news(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:news", "news",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    5615, 32),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 3810,
    20),
    )
)

internal val Res.string.next: StringResource
  get() = CommonMainString0.next

private fun init_next(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:next", "next",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    5648, 28),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 3831,
    20),
    )
)

internal val Res.string.no_definitions: StringResource
  get() = CommonMainString0.no_definitions

private fun init_no_definitions(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:no_definitions", "no_definitions",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    5677, 102),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 3852,
    66),
    )
)

internal val Res.string.password: StringResource
  get() = CommonMainString0.password

private fun init_password(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:password", "password",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    6147, 32),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 4158,
    28),
    )
)

internal val Res.string.password_cannot_be_empty: StringResource
  get() = CommonMainString0.password_cannot_be_empty

private fun init_password_cannot_be_empty(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:password_cannot_be_empty", "password_cannot_be_empty",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    5780, 100),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 3919,
    64),
    )
)

internal val Res.string.password_invalid: StringResource
  get() = CommonMainString0.password_invalid

private fun init_password_invalid(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:password_invalid", "password_invalid",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    5881, 128),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 3984,
    80),
    )
)

internal val Res.string.password_must_be_between: StringResource
  get() = CommonMainString0.password_must_be_between

private fun init_password_must_be_between(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:password_must_be_between", "password_must_be_between",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    6010, 136),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 4065,
    92),
    )
)

internal val Res.string.press_kit: StringResource
  get() = CommonMainString0.press_kit

private fun init_press_kit(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:press_kit", "press_kit",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    6180, 41),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 4187,
    29),
    )
)

internal val Res.string.private_police: StringResource
  get() = CommonMainString0.private_police

private fun init_private_police(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:private_police", "private_police",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    6222, 94),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 4217,
    42),
    )
)

internal val Res.string.profile: StringResource
  get() = CommonMainString0.profile

private fun init_profile(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:profile", "profile",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    6317, 35),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 4260,
    27),
    )
)

internal val Res.string.register: StringResource
  get() = CommonMainString0.register

private fun init_register(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:register", "register",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    6482, 64),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 4393,
    28),
    )
)

internal val Res.string.register_subtitle: StringResource
  get() = CommonMainString0.register_subtitle

private fun init_register_subtitle(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:register_subtitle", "register_subtitle",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    6353, 73),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 4288,
    69),
    )
)

internal val Res.string.register_title: StringResource
  get() = CommonMainString0.register_title

private fun init_register_title(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:register_title", "register_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    6427, 54),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 4358,
    34),
    )
)

internal val Res.string.request_failed: StringResource
  get() = CommonMainString0.request_failed

private fun init_request_failed(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:request_failed", "request_failed",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    6547, 78),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 4422,
    50),
    )
)

internal val Res.string.request_timeout: StringResource
  get() = CommonMainString0.request_timeout

private fun init_request_timeout(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:request_timeout", "request_timeout",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    6626, 271),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 4473,
    123),
    )
)

internal val Res.string.reset_your_password: StringResource
  get() = CommonMainString0.reset_your_password

private fun init_reset_your_password(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:reset_your_password", "reset_your_password",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    6898, 67),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 4597,
    55),
    )
)

internal val Res.string.science_and_education: StringResource
  get() = CommonMainString0.science_and_education

private fun init_science_and_education(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:science_and_education", "science_and_education",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    6966, 77),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 4653,
    57),
    )
)

internal val Res.string.search: StringResource
  get() = CommonMainString0.search

private fun init_search(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:search", "search",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7044, 30),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 4711,
    22),
    )
)

internal val Res.string.sign_up: StringResource
  get() = CommonMainString0.sign_up

private fun init_sign_up(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:sign_up", "sign_up",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7075, 63),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 4734,
    27),
    )
)

internal val Res.string.sport: StringResource
  get() = CommonMainString0.sport

private fun init_sport(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:sport", "sport",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7139, 29),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 4762,
    21),
    )
)

internal val Res.string.streak: StringResource
  get() = CommonMainString0.streak

private fun init_streak(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:streak", "streak",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7169, 30),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 4784,
    22),
    )
)

internal val Res.string.technologies: StringResource
  get() = CommonMainString0.technologies

private fun init_technologies(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:technologies", "technologies",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7200, 48),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 4807,
    36),
    )
)

internal val Res.string.topic_list_empty: StringResource
  get() = CommonMainString0.topic_list_empty

private fun init_topic_list_empty(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:topic_list_empty", "topic_list_empty",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7249, 72),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 4844,
    56),
    )
)

internal val Res.string.topics: StringResource
  get() = CommonMainString0.topics

private fun init_topics(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:topics", "topics",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7322, 26),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 4901,
    22),
    )
)

internal val Res.string.trips: StringResource
  get() = CommonMainString0.trips

private fun init_trips(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:trips", "trips",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7349, 45),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 4924,
    21),
    )
)

internal val Res.string.unexpected_error: StringResource
  get() = CommonMainString0.unexpected_error

private fun init_unexpected_error(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:unexpected_error", "unexpected_error",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7395, 104),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 4946,
    64),
    )
)

internal val Res.string.usage_examples: StringResource
  get() = CommonMainString0.usage_examples

private fun init_usage_examples(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:usage_examples", "usage_examples",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7500, 78),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 5011,
    42),
    )
)

internal val Res.string.username: StringResource
  get() = CommonMainString0.username

private fun init_username(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:username", "username",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7579, 60),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 5054,
    28),
    )
)

internal val Res.string.welcome_back: StringResource
  get() = CommonMainString0.welcome_back

private fun init_welcome_back(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:welcome_back", "welcome_back",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7640, 56),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 5083,
    36),
    )
)

internal val Res.string.whats_new: StringResource
  get() = CommonMainString0.whats_new

private fun init_whats_new(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:whats_new", "whats_new",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7697, 45),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 5120,
    29),
    )
)

internal val Res.string.word_not_found: StringResource
  get() = CommonMainString0.word_not_found

private fun init_word_not_found(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:word_not_found", "word_not_found",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7743, 90),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 5150,
    54),
    )
)

internal val Res.string.wrong_login_or_password: StringResource
  get() = CommonMainString0.wrong_login_or_password

private fun init_wrong_login_or_password(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:wrong_login_or_password", "wrong_login_or_password",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7834, 95),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 5205,
    63),
    )
)

internal val Res.string.xp: StringResource
  get() = CommonMainString0.xp

private fun init_xp(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:xp", "xp",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7930, 22),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 5269,
    14),
    )
)

internal val Res.string.your_statistics: StringResource
  get() = CommonMainString0.your_statistics

private fun init_your_statistics(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:your_statistics", "your_statistics",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ru"),
    ), "composeResources/ailingo.composeapp.generated.resources/values-ru/strings.commonMain.cvr",
    7953, 63),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/values/strings.commonMain.cvr", 5284,
    43),
    )
)
