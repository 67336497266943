package org.ailingo.app

import app.cash.sqldelight.SuspendingTransacter
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlDriver
import app.cash.sqldelight.db.SqlSchema
import kotlin.Unit
import org.ailingo.app.composeApp.newInstance
import org.ailingo.app.composeApp.schema

public interface AppDatabase : SuspendingTransacter {
  public val historyDictionaryQueries: HistoryDictionaryQueries

  public val tokenQueries: TokenQueries

  public companion object {
    public val Schema: SqlSchema<QueryResult.AsyncValue<Unit>>
      get() = AppDatabase::class.schema

    public operator fun invoke(driver: SqlDriver): AppDatabase =
        AppDatabase::class.newInstance(driver)
  }
}
